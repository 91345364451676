import styled from 'styled-components'
import media from 'styled-media-query'

export const Container = styled.section`
  align-items: center;
  max-width: 1280px;
  border-top: 1px solid #121214;
  color: #d9d9d9;
  display: flex;
  margin: 0 auto;
  justify-content: space-between;
  padding: 2rem 1rem;

  ${media.lessThan('medium')`
    font-size: 14px;
  `}

  a {
    color: #d9d9d9;
    text-decoration: none;
    transition: color 0.5s;
    &:hover {
      color: #38b6ff;
    }
  }
`
