import styled from "styled-components"
import media from 'styled-media-query'

export const Container = styled.section`
  max-width: 1280px;
  margin: 0 auto;

  body#grid & {
    background-color: #3E3B47;
    border-bottom: 1px solid #121214;
    display: grid;
    grid-area: posts;
    grid-gap: 1px;
    grid-template-columns: repeat(auto-fit, minmax(380px, 1fr));
  }
`

export const Title = styled.h2`
  font-size: 34px;
  font-weight: bold;
  line-height: 48px;
  color: #ffdb77;
  margin-left: 1rem;
  margin-bottom: 24px;

  ${media.lessThan("large")`
    font-size: 28px;
    line-height: 40px;
  `}

  ${media.lessThan("medium")`
    font-size: 22px;
    line-height: 32px;
  `}
`;
