import styled from "styled-components"
import media from 'styled-media-query'

export const WelcomeWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 1280px;
  margin: 0 auto;
  padding-top: 3rem;
  padding-left: 3rem;
  padding-right: 3rem;

  ${media.lessThan("large")`
    padding-top: 2rem;
    padding-left: 2rem;
    padding-right: 2rem;
  `}

  ${media.lessThan("medium")`
    display: block;
  `}

  ${media.lessThan("small")`
    margin: 0.5rem auto;
    padding: 1rem;
  `}
`;

export const SloganWrapper = styled.div`
  width: 580px;

  ${media.lessThan("large")`
    width: 480px;
  `}

  ${media.lessThan("medium")`
    width: 100%;
    display: block;
    margin: 1.5rem auto;
  `}
`;

export const SloganTitle = styled.div`
  font-size: 34px;
  font-weight: bold;
  line-height: 48px;
  color: #ffdb77;
  margin-bottom: 24px;

  ${media.lessThan("large")`
    font-size: 28px;
    line-height: 40px;
  `}

  ${media.lessThan("medium")`
    font-size: 22px;
    line-height: 32px;
  `}
`;

export const SloganDescription = styled.div`
  font-size: 24px;
  font-weight: 300;
  line-height: 44px;
  color: #d9d9d9;

  ${media.lessThan("large")`
    font-size: 20px;
    line-height: 30px;
  `}

  ${media.lessThan("medium")`
    font-size: 18px;
    line-height: 28px;
  `}
`;

export const ImageHome = styled.img`
  max-height: 560px;
  max-width: 560px;

  ${media.lessThan("large")`
    max-height: 420px;
    max-width: 420px;
  `}

  ${media.lessThan("small")`
    width: 100%;
  `}
`

export const RedesSociaisUl = styled.ul`
  display: flex;
  background-color: #09090A;
  align-items: center;
  justify-content: center;
  list-style: none;
  margin: 0 auto;

  ${media.greaterThan("medium")`
    display: none;
  `}
`

export const RedesSociaisLi = styled.li`
  padding: 0;
  margin-top: 48px;

  & + li {
    margin-left: 32px;
  }
`

export const RedesSociaisLink = styled.a`
  color: #d9d9d9;
  text-decoration: none;
  &:hover {
    color: #ffdb77;
  }
`

export const RedesSociaisIcon = styled.div`
  fill: #d9d9d9;
  width: 48px;
  height: 48px;
`
