import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import SEO from "../components/seo"
import PostItem from "../components/PostItem"
import Pagination from '../components/Pagination'

import { Container, Title } from "../components/ListWrapper/styles"
import {
  WelcomeWrapper,
  SloganWrapper,
  SloganTitle,
  SloganDescription,
  ImageHome,
  RedesSociaisIcon,
  RedesSociaisLi,
  RedesSociaisLink,
  RedesSociaisUl,
} from '../components/Home/styles'
import links from "../components/SocialLinks/content"
import Icons from "../components/SocialLinks/Icons"
import { AppLink } from "../components/Courses/styles"

const BlogList = props => {
  const postList = props.data.allMarkdownRemark.edges

  const { currentPage, numPages } = props.pageContext
  const isFirst = currentPage === 1
  const isLast = currentPage === numPages
  const prevPage = currentPage - 1 === 1 ? '/' : `/page/${currentPage - 1}`
  const nextPage = `/page/${currentPage + 1}`

  return (
    <Layout>
      <SEO title="Home" />
      <WelcomeWrapper>
        <SloganWrapper>
          <SloganTitle>
            Olá, seja bem-vinda e<br />
            bem-vindo ao portal<br />
            Aluizio Developer!
          </SloganTitle>
          <SloganDescription>
            Muito conteúdo sobre desenvolvimento de aplicativos
            back-end, front-end, mobile e muito mais.
            Aproveite para consumir&nbsp;
            <AppLink href="https://letsgoahead.com.br/" target="_blank" rel="noopener noreferrer">
              os cursos gratuitos na plataforma online.
            </AppLink>
          </SloganDescription>
        </SloganWrapper>

        <ImageHome src="/assets/img/home01.png" alt="Home" />
      </WelcomeWrapper>

      <Container>

        <Title>Posts mais recentes</Title>
        {postList.map(
          ({
            node: {
              frontmatter: { background, category, date, description, title },
              timeToRead,
              fields: { slug },
            },
          }, i) => (
            <PostItem
              key={i}
              slug={slug}
              background={background}
              category={category}
              date={date}
              timeToRead={timeToRead}
              title={title}
              description={description}
            />
          )
        )}
      </Container>

      <Pagination
        isFirst={isFirst}
        isLast={isLast}
        currentPage={currentPage}
        numPages={numPages}
        prevPage={prevPage}
        nextPage={nextPage}
      />

      <RedesSociaisUl>
        {links.map((link, i) => {
          const Icon = Icons[link.label]

          return (
            <RedesSociaisLi key={i}>
              <RedesSociaisLink
                href={link.url} title={link.label}
                target="_blank"
                rel="noopener noreferrer"
              >
                <RedesSociaisIcon>
                  <Icon />
                </RedesSociaisIcon>
              </RedesSociaisLink>
            </RedesSociaisLi>
          )
        })}
      </RedesSociaisUl>
    </Layout>
  )
}

export const query = graphql`
  query PostsList($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      sort: { fields: frontmatter___date, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            background
            category
            date(locale: "pt-br", formatString: "DD [de] MMMM [de] YYYY")
            description
            title
          }
          timeToRead
        }
      }
    }
  }
`

export default BlogList
